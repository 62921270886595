import React, { Component } from 'react'
import ExportVersion from './ExportVersion'
import { ListGroup, ListGroupItem } from 'reactstrap'

export default class AppDetails extends Component {
    

    render() {
        return (
            <div>
                
                <h3>{this.props.current_app} <small>(current version: {this.props.current_version})</small> </h3>
                <ul>
                    {this.props.versions.map((version) => <li key={version.version}>
                    <ListGroup>
                        <ListGroupItem>Version: {version.version}</ListGroupItem>
                        <ListGroupItem>Total Items: {version.total_items}</ListGroupItem>
                        <ListGroupItem>Shuffle: {version.shuffle}</ListGroupItem>
                        <ExportVersion current_app={this.props.current_app} version={version.version} items={version.items}></ExportVersion>
                    </ListGroup>      
                    </li>
                    )}
                </ul>
            </div>
        )
    }
}
