import React, { Component } from 'react'
import { Button, Input, Form, Row, Col, Jumbotron, Alert, FormGroup, Label, Badge } from 'reactstrap';
import DyisApp from './DyisApp'

export default class UploadForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      url: '',
      fields: {},
      ok: false,
      apps: [],
      current_version: '',
      total_items: 0,
      items: [],
      current_app: '',
      current_app_versions: [],
    }
    this.api_id = process.env.REACT_APP_API_GATEWAY_ID
    this.stage = process.env.REACT_APP_STAGE
    this.handleSubmit = this.handleSubmit.bind(this)
    this.uploadChange = this.uploadChange.bind(this)
    this.fileInput = React.createRef();
    this.random = React.createRef();
    this.headers = { 'x-api-key': process.env.REACT_APP_API_KEY }
  }

  componentDidMount() {
    fetch(`https://${this.api_id}.execute-api.us-east-1.amazonaws.com/${this.stage}/dyis/apps`, {
      headers: this.headers
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          apps: data.body
        })
      });
  }

  async handleSubmit(e) {
    e.preventDefault()
    let random = this.random.current.value
    let filename = `${random}_${this.fileInput.current.files[0].name}`
    let uploadForm = document.getElementById('uploadForm')
    await fetch(`https://${this.api_id}.execute-api.us-east-1.amazonaws.com/${this.stage}/dyis/get_signed_url`, {
      method: 'post',
      headers: this.headers,
      body: JSON.stringify({ filename }),
    })
      .then(res => res.json())
      .then(data => this.setState({ url: data.url, fields: data.fields }));
    fetch(this.state.url, {
      method: 'POST',
      headers: this.headers,
      body: new FormData(uploadForm)
    }).then(res => {
      document.getElementById('fileSelectorLabel').innerHTML = "Choose File"
      this.setState({ url: '', fields: {} });
      document.getElementById('fileSelector').value = null
      this.setState({ ok: res.ok })
    });
  }

  app_detail(app_id) {
    fetch(`https://${this.api_id}.execute-api.us-east-1.amazonaws.com/${this.stage}/dyis/app_details/${app_id}`, {
      headers: this.headers
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          current_version: data.body.current_version,
          current_app: app_id,
          current_app_versions: data.body.versions
        })
      });
  }

  uploadChange(e) {
    let filename = this.fileInput.current.files[0].name
    document.getElementById('fileSelectorLabel').innerHTML = filename
  }

  render() {
    return (
      <div>
        <Jumbotron>
          <h1>Dynamic Item Servers <small>{this.stage}</small></h1>
          <hr className="my-2" />
          <h3>Serving Wharton since 2020</h3>
        </Jumbotron>
        <Row>
          <Col sm="3">
            <div className="lead">Apps</div>
            <ul>
              {this.state.apps.map((e) =>
                <li key={e.app_name}>
                  <p style= {{cursor: 'pointer'}}onClick={() => this.app_detail(e.app_name)}>{e.app_name} <Badge color={ e.max_served_count > 0 ? "primary" : 'secondary'} >{e.max_served_count}</Badge></p>
                  
                  </li>
              )}
            </ul>
          </Col>
          <Col sm="9">
            <div>
              {this.state.ok ? <Alert color="success">
                Upload Successful
          </Alert>
                : ""}
            </div>
            <Form id="uploadForm" onSubmit={this.handleSubmit}
              method="post"
              encType="multipart/form-data">
              {Object.keys(this.state.fields).map(key => {
                return <input readOnly key={key} type="hidden" name={key} value={this.state.fields[key]} />
              })}
              <FormGroup>
                <div className="custom-file">
                  <Label className="custom-file-label" id="fileSelectorLabel" ref={this.filename} htmlFor="customFile">Choose file</Label>
                  <Input className="custom-file-input" id="fileSelector" type="file" name="file" innerRef={this.fileInput} onChange={this.uploadChange} />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">OnNext shuffles the order when Next is called. Always shuffles anytime it is touched</Label>
                <Input type="select" name="random" innerRef={this.random} id="randomSelect">
                  <option>None</option>
                  <option>OnNext</option>
                  <option>Always</option>
                </Input>
              </FormGroup>
              <Button type="submit">Upload</Button>
            </Form>
            <hr />
            <div>
              {this.state.current_app_versions.length ?
                <DyisApp
                  versions={this.state.current_app_versions}
                  current_app={this.state.current_app}
                  current_version={this.state.current_version}
                />
                : ""
              }
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
