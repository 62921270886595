import React, { Component } from 'react'
import { Button, ButtonGroup, Collapse } from 'reactstrap'
import confirm from "reactstrap-confirm";

export default class ExportVersion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            download_urls: {},
            reset: '',
            is_open: false
        }
        this.api_id = process.env.REACT_APP_API_GATEWAY_ID
        this.stage = process.env.REACT_APP_STAGE
        this.headers = { 'x-api-key': process.env.REACT_APP_API_KEY }
        this.get_app_data = this.get_app_data.bind(this)
        this.download = this.download.bind(this)
        this.reset = this.reset.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    toggle(version){
        
        this.setState({isOpen: !this.state.isOpen})
    }

    

    get_app_data(app, version) {
        
        fetch(`https://${this.api_id}.execute-api.us-east-1.amazonaws.com/${this.stage}/dyis/export/${app}/${version}`, {
            headers: this.headers
        })
            .then(res => res.json())
            .then(data => {
                var download_urls = {...this.state.download_urls}
                download_urls[`${app}_${version}`] = data.body
                this.setState({download_urls})
            });
    }
    

    download(app, version){
        this.get_app_data(this.props.current_app, version)
    }

    async reset(app, version){
        let result = await confirm();
        if (result === true){
            fetch(`https://${this.api_id}.execute-api.us-east-1.amazonaws.com/${this.stage}/dyis/reset_version/${app}/${version}`, {
                headers: this.headers
            })
            .then(res => res.json())
            .then(data => {
                this.setState({reset: data.body})
            });
        }
        else{
            return false
        }

    }
    

    render() {
        return (
            <div>
                 <ButtonGroup>
                    <Button onClick={() => this.reset(this.props.current_app, this.props.version)} color="danger" size="sm">Reset Served</Button>
                    <Button onClick={() => this.download(this.props.current_app, this.props.version)}>Generate Data for Export</Button>
                    <Button color="primary" onClick={this.toggle}>Show/Hide Payload</Button>
                </ButtonGroup>
                <p className="text-success">
                    {this.state.reset}
                </p>
                {this.state.download_urls.hasOwnProperty(`${this.props.current_app}_${this.props.version}`) ? 
                    <a href={this.state.download_urls[`${this.props.current_app}_${this.props.version}`]}>Download Data for {this.props.current_app}({this.props.version})</a>
                    :
                    ""
                }
                <Collapse isOpen={this.state.isOpen}>
                <div>
                    <pre className="pre-scrollable">
                        <code>
                            {JSON.stringify(this.props.items, null, 2)}
                        </code>
                    </pre>
                </div>
            </Collapse>
            </div>
        )
    }
}
