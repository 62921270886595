
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e7657bd9-298a-4b96-91da-e8ae6fce38b9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_i5Y9mf6YK",
    "aws_user_pools_web_client_id": "1irq1e6lkj4blpmjbslfqvce3l",
    "oauth": {},
    "aws_content_delivery_bucket": "frontend-20200305130313-amplify-dev-local",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://frontend-20200305130313-amplify-dev-local.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;